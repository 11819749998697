import { ORDER_STATUSES } from '@ubeya/shared/constants';

export const TIME_RANGE_KEY_OPTIONS = {
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  BI_WEEKLY: 'biWeekly'
};

export const CALENDAR_VIEW_OPTIONS = [
  { label: 'schedulingBoard', value: 'weekly' },
  { label: 'calendar', value: 'monthly' }
];

export const WEEKLY_GROUP_BY_VIEW_OPTIONS = [
  { label: 'employee', value: 'employee' },
  { label: 'shift', value: 'shift' },
  { label: 'position', value: 'position' },
  { label: 'client', value: 'client' }
];

export const MONTHLY_VIEW_OPTIONS = [
  { label: 'monthView', value: 'monthView' },
  { label: 'weekView', value: 'weekView' },
  { label: 'monthListView', value: 'monthListView' },
  { label: 'weekListView', value: 'weekListView' }
];

export const MONTHLY_VIEW_OPTIONS_MAPPED = {
  MONTH_VIEW: 'monthView',
  WEEK_VIEW: 'weekView',
  MONTH_LIST_VIEW: 'monthListView',
  WEEK_LIST_VIEW: 'weekListView'
};

export const TIME_RANGE_OPTIONS = [
  { label: 'week', value: TIME_RANGE_KEY_OPTIONS.WEEKLY, amount: 1, units: 'week' },
  { label: 'month', value: TIME_RANGE_KEY_OPTIONS.MONTHLY, amount: 1, units: 'month' }
];

export const PROJECT_GROUP_BY_OPTIONS = {
  LOCATION: 'location',
  POSITION: 'position',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  DATE: 'date',
  COST_CENTER: 'costCenter',
  LAT_LNG: 'latLng'
};

export const PRODUCTIVITY_RATE_DEFAULT = 2.5;

export const DRIVER_POSITION_SLUG = 'position__driver';
export const CHOPPER_POSITION_SLUG = 'Chopper';
export const NINJA_POSITION_SLUG = 'TSR 1';

export const MAX_HOURS_SHOW_WARNING = 40;

export const MONTHLY_VIEW_OPTIONS_MAP = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  DEFAULT: 'month'
};

export const GROUP_BY_OPTIONS = {
  EMPLOYEE: 'employee',
  SHIFT: 'shift',
  POSITION: 'position',
  CLIENT: 'client'
};

export const TIME_AVAILABILITY_BOOKING_OPTIONS = {
  BY_OPEN_SHIFTS: 1,
  BY_LABEL: 2
};

export const BOOKING_ACTIONS = {
  bookToOpenShift: 'bookToOpenShift',
  bookToNewShift: 'bookToNewShift',
  addShiftWithEmployeeBooked: 'addShiftWithEmployeeBooked',
  createOpenShift: 'createOpenShift',
  editBookedShift: 'editBookedShift',
  createOpenShiftWithBookedEmployees: 'createOpenShiftWithBookedEmployees',
  editOpenShift: 'editOpenShift',
  deleteBookedShift: 'deleteBookedShift',
  deleteOpenShift: 'deleteOpenShift',
  editBookedShiftTimesheet: 'editBookedShiftTimesheet',
  moveSlot: 'moveSlot',
  updateShiftNeeded: 'updateShiftNeeded',
  deleteShiftFromEmployeeBooking: 'deleteShiftFromEmployeeBooking',
  handleUpdateTimesheet: 'handleUpdateTimesheet'
};

//Drag and drop constants
export const DRAG_AND_DROP_OPTIONS = {
  WEEKLY_OPEN_SHIFT: 'weeklyOpenShift',
  WEEKLY_BOOKED_SHIFT: 'weeklyBookedShift',
  WEEKLY_UNBOOK_EMPLOYEE: 'weeklyUnbookEmployee',
  DAILY_OPEN_SHIFT: 'dailyyOpenShift',
  DAILY_BOOKED_SHIFT: 'dailyBookedShift',
  DAILY_UNBOOK_EMPLOYEE: 'dailyUnbookEmployee'
};

export const GROUP_BY_EMPLOYEE_VIEW = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BI_WEEKLY: 'biWeekky'
};

export const AVAILABILITY_REQUEST_TYPE = {
  PREFER: 0,
  PREFER_NOT: 1,
  TIME_OFF: 2
};

export const LIST_TYPE_ENUM = {
  APPLIED: 1,
  AVAILABLE: 2,
  REJECTED_AVAILABLITY: 3,
  REJECTED_APPLICATION: 4,
  OTHER: 5,
  NOT_AVAILABLE_GA: 6,
  AVAILABLE_GA: 7,
  PREFERRED: 8,
  NOT_PREFERRED: 9,
  PARTIAL_PREFERRED: 10,
  WARNING: 11
};

export const WARNING_SEVERITY_OPTIONS = {
  WARNING: 'warning'
};

export const MIN_WEEKLY_HOURS_WARNING = 20;

export const ACCOUNT_IDS_FOR_EXCEED_WORKING_HOURS = [2, 8, 6673, 12650, 11571];

export const MULTI_DAY_EVENT_OPTIONS = {
  ALL: 'allDates'
};

export const AREAS_OPTIONS = {
  ALL: 'allAreas',
  DEFAULT_AREA: 'defaultArea',
  OTHER_AREAS: 'otherAreas'
};

export const COST_CENTER_OPTIONS = {
  ALL: 'allCostCenters',
  NO_COST_CENTER: 'noCostCenter'
};

export const POSITIONS_OPTIONS = {
  ALL: 'allPositions',
  NO_POSITION: 'noPosition'
};

export const START_TIME_OPTIONS = {
  ALL: 'allStartTimes'
};

export const END_TIME_OPTIONS = {
  ALL: 'allEndTimes'
};

export const APPLICATION_TYPE = {
  PER_SHIFT: 1,
  PER_PROJECT: 2
};

export const SLOT_WARNING_TYPES = {
  BOOKED_SAME_DAY: {
    type: 1
  },
  RELEASE_REQUEST: {
    type: 2
  },
  DAY_OFF: {
    type: 3
  },
  DN_SPECIFIC_EMIRATES: {
    type: 4
  },
  DN_EVENT_ACCREDITATION: {
    type: 5
  },
  DN_VISA_EXPIRY: {
    type: 6
  },
  COMPLIANCE: {
    type: 7
  },
  WTD: {
    type: 8
  }
};

export const BOOKING_USER_PROMPT_CASES = {
  POSITION: 1,
  COMPLIANCE: 2,
  EXCEED_WORKING_HOURS: 3,
  BOOKED_SAME_DAY: 4,
  IS_PENDING: 5,
  COMPLIANCE_BLOCK: 6
};

export const LIST_TYPE_COLORS = {
  1: '#10B07C',
  2: '#10B07C',
  3: '#ff6163',
  4: '#ff6163',
  5: '#d9d9d9',
  6: '#ff6163',
  7: '#10B07C',
  8: '#3cd7a9',
  9: '#ff6163',
  10: '#D28313',
  11: '#D28313',
  100: '#10B07C'
};

export const APPLIED_EMPLOYEES = [
  LIST_TYPE_ENUM.APPLIED,
  LIST_TYPE_ENUM.AVAILABLE,
  LIST_TYPE_ENUM.APPLIED_WITH_WARNINGS
];

export const REJECTED_EMPLOYEES = [LIST_TYPE_ENUM.REJECTED_APPLICATION, LIST_TYPE_ENUM.REJECTED_AVAILABLITY];

export const PROJECT_SIDE_COLUMNS_OPTIONS = {
  PROJECT_DETAILS: 'projectDetails',
  PROJECT_INDEX: 'projectIndex'
};

export const INACTIVE_ORDER_TYPES = [ORDER_STATUSES.CANCELED.label];
