import { get } from 'lodash';
import moment from 'moment';
import { ACCOUNT_BILLING_STATUS_MAP, API_DATE_FORMAT, API_DATE_TIME_FORMAT, ORDER_STATUSES } from '../constants';

export const SORT_TIME = (a, b, format) =>
  moment(a, format ?? API_DATE_TIME_FORMAT).format('HHmm') - moment(b, format ?? API_DATE_TIME_FORMAT).format('HHmm');

export const SORT_STRING = (a, b) => a?.localeCompare?.(b) || 0;

export const SORT_BY_STRING = (key) => (a, b) => {
  const aValue = get(a, key, '');
  const bValue = get(b, key, '');

  return SORT_STRING(aValue, bValue);
};

export const SORT_BY_DATE_AND_LOCATION = (key, mappedLocations, projectLocationId) => (a, b) => {
  // First, sort by date using SORT_BY_DATE
  const dateComparison = SORT_BY_DATE(key)(a, b);
  // If dates are equal, sort by location name
  if (dateComparison === 0) {
    const aIsProjectLocation = a?.locationId === projectLocationId;
    const bIsProjectLocation = b?.locationId === projectLocationId;

    // If one shift has the projectLocationId and the other doesn't, prioritize the project location
    if (aIsProjectLocation && !bIsProjectLocation) {
      return -1; // Put a above b
    }
    if (!aIsProjectLocation && bIsProjectLocation) {
      return 1; // Put b above a
    }
    return SORT_BY_LOCATION_NAME(a, b, mappedLocations);
  }

  // Otherwise, return the result of the date comparison
  return dateComparison;
};

export const SORT_BY_LOCATION_NAME = (a, b, mappedLocations) => {
  const aValue = mappedLocations?.[a.locationId]?.name || '';
  const bValue = mappedLocations?.[b.locationId]?.name || '';

  return aValue?.localeCompare?.(bValue) || 0;
};

export const SORT_BY_FIRST_ELEMENT_STRING_ARRAY = (key) => (a, b) => {
  const aValue = get(a, key, '')?.[0] || '';
  const bValue = get(b, key, '')?.[0] || '';

  return aValue?.localeCompare?.(bValue) || 0;
};

export const SORT_BY_POSITION = (
  { positionId: positionIdA, isExternal: isExternalA, supplierData: supplierDataA },
  { positionId: positionIdB, isExternal: isExternalB, supplierData: supplierDataB },
  mappedPositions,
  t
) => {
  const positionA = positionIdA
    ? t(mappedPositions[positionIdA]?.slug)
    : isExternalA
    ? t(supplierDataA?.positionSlug)
    : '';

  const positionB = positionIdB
    ? t(mappedPositions[positionIdB]?.slug)
    : isExternalB
    ? t(supplierDataB?.positionSlug)
    : '';

  const aValue = positionA || '';
  const bValue = positionB || '';

  return aValue?.localeCompare?.(bValue) || 0;
};

export const SORT_BY_ONLY_EXTERNAL_POSITION = (
  { positionId: positionIdA },
  { positionId: positionIdB },
  mappedSuppliersPositions,
  t
) => {
  const positionA = positionIdA ? t(mappedSuppliersPositions[positionIdA]?.slug) : '';

  const positionB = positionIdB ? t(mappedSuppliersPositions[positionIdB]?.slug) : '';

  const aValue = positionA || '';
  const bValue = positionB || '';

  return aValue?.localeCompare?.(bValue) || 0;
};

export const SORT_BY_SUPPLIER_NAME = (a, b, mappedSuppliers) => {
  const aValue = a.supplierAccountId ? mappedSuppliers[a.supplierAccountId].name : '';
  const bValue = b.supplierAccountId ? mappedSuppliers[b.supplierAccountId].name : '';

  return aValue?.localeCompare?.(bValue) || 0;
};

export const SORT_BY_NAME = (a, b) =>
  `${a?.employee?.firstName || a?.firstName}${a?.employee?.lastName || a?.lastName}`.localeCompare(
    `${b?.employee?.firstName || b?.firstName}${b?.employee?.lastName || b?.lastName}`
  );

export const SORT_BY_DATE_NO_KEY = (aValue, bValue, format = API_DATE_TIME_FORMAT) => {
  if (aValue && !bValue) {
    return 1;
  }
  if (!aValue && bValue) {
    return -1;
  }
  if (!aValue && !bValue) {
    return 0;
  }

  return moment(aValue, format).diff(moment(bValue, format));
};

export const SORT_BY_DATE_ONLY = (key) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  return SORT_BY_DATE_NO_KEY(aValue, bValue, API_DATE_FORMAT);
};

export const SORT_BY_DATE = (key) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  return SORT_BY_DATE_NO_KEY(aValue, bValue);
};

export const SORT_BY_BREAKS_DURATION = (key) => (a, b) => {
  const aValue = get(a, key) || [];
  const bValue = get(b, key) || [];

  const totalDurationA = aValue
    ?.filter(
      ({ startTime, endTime }) => startTime && moment(startTime).isValid() && endTime && moment(endTime).isValid()
    )
    .map(({ startTime, endTime }) => totalTime(startTime, endTime))
    .reduce((all, duration) => all + duration, 0);

  const totalDurationB = bValue
    ?.filter(
      ({ startTime, endTime }) => startTime && moment(startTime).isValid() && endTime && moment(endTime).isValid()
    )
    .map(({ startTime, endTime }) => totalTime(startTime, endTime))
    .reduce((all, duration) => all + duration, 0);

  return SORT_BY_NUMBER_NO_KEY(totalDurationA, totalDurationB);
};

export const SORT_BY_TIME = (key, format) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return -1;
  }
  if (!bValue && aValue) {
    return 1;
  }
  if (!aValue && !bValue) {
    return 0;
  }

  return SORT_TIME(aValue, bValue, format);
};

export const SORT_BY_DATE_AND_TIME = (key, format, reverse = false) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return reverse ? 1 : -1;
  }
  if (bValue && !aValue) {
    return reverse ? -1 : 1;
  }
  if (!aValue && !bValue) {
    return 0;
  }
  if (reverse) {
    return moment(bValue, format ?? API_DATE_TIME_FORMAT) - moment(aValue, format ?? API_DATE_TIME_FORMAT);
  }
  return moment(aValue, format ?? API_DATE_TIME_FORMAT) - moment(bValue, format ?? API_DATE_TIME_FORMAT);
};

export const SORT_BY_MIN_TIME = (key, format) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return -1;
  }
  if (!bValue && aValue) {
    return 1;
  }
  if (!aValue && !bValue) {
    return 0;
  }

  const comparisonResult =
    moment(aValue, format ?? API_DATE_TIME_FORMAT).format('HHmm') -
    moment(bValue, format ?? API_DATE_TIME_FORMAT).format('HHmm');

  if (comparisonResult === 0) {
    return SORT_BY_TOTAL_TIME(b, a);
  }
  return comparisonResult;
};

const totalTime = (start, end) => (start && end ? moment(end).diff(moment(start)) : 0);
export const SORT_BY_TOTAL_TIME = (a, b) => totalTime(a.startTime, a.endTime) - totalTime(b.startTime, b.endTime);

export const SORT_BY_TOTAL = (a, b) => totalTime(a.actualIn, a.actualOut) - totalTime(b.actualIn, b.actualOut);
export const SORT_BY_ACTUAL_TOTAL = (a, b) => a.actualTotal - b.actualTotal;

export const SORT_BY_NUMBER_NO_KEY = (a, b) => a - b;

export const SORT_BY_NUMBER = (key, secondKey) => (a, b) => {
  let aValue = get(a, key) || 0;
  let bValue = get(b, key) || 0;

  const result = aValue - bValue;

  if (result !== 0 || !secondKey) {
    return result;
  }

  aValue = get(a, secondKey);
  bValue = get(b, secondKey);
  return aValue - bValue;
};

export const SORT_BY_BOOLEAN_NO_KEY = (aValue, bValue) => {
  if (aValue && !bValue) {
    return -1;
  }
  // TODO remove this as it's the same as condition above
  if (!bValue && aValue) {
    return 1;
  }
  return 0;
};

export const SORT_BY_BOOLEAN = (key) => (a, b) => {
  const aValue = get(a, key);
  const bValue = get(b, key);

  if (aValue && !bValue) {
    return -1;
  }
  // TODO remove this as it's the same as condition above
  if (!bValue && aValue) {
    return 1;
  }
  return 0;
};

export const SORT_BY_BILLING_STATUS = (account1, account2) => {
  if (account1?.status === account2?.status) {
    return 0;
  }
  if (
    [ACCOUNT_BILLING_STATUS_MAP.PAYING, ACCOUNT_BILLING_STATUS_MAP.INTERNAL].includes(account1?.status) &&
    [ACCOUNT_BILLING_STATUS_MAP.PAYING, ACCOUNT_BILLING_STATUS_MAP.INTERNAL].includes(account2?.status)
  ) {
    return 0;
  }
  if ([ACCOUNT_BILLING_STATUS_MAP.PAYING, ACCOUNT_BILLING_STATUS_MAP.INTERNAL].includes(account2?.status)) {
    return 1;
  }
  return -1;
};

export const SORT_BY_ORDER_STATUS = (order1, order2) => {
  if (order1?.status === order2?.status) {
    return 0;
  }

  /*
    If a should come before b in the sorted order, the comparison function should return a negative value (e.g., -1).
    If a should come after b in the sorted order, the comparison function should return a positive value (e.g., 1).
    If a and b are considered equivalent in terms of sorting order, the comparison function should return 0.
 */

  if ([ORDER_STATUSES.DRAFT.label].includes(order1?.status)) return -1;
  if ([ORDER_STATUSES.DRAFT.label].includes(order2?.status)) return 1;

  if ([ORDER_STATUSES.CANCELED.label].includes(order1?.status)) {
    return 1;
  }

  if ([ORDER_STATUSES.CANCELED.label].includes(order2?.status)) {
    return -1;
  }

  return 0;
};

export const SORT_MAP = {
  text: SORT_BY_STRING,
  phone: SORT_BY_STRING,
  number: SORT_BY_NUMBER,
  boolean: SORT_BY_BOOLEAN,
  date: SORT_BY_DATE,
  time: SORT_BY_NUMBER,
  currency: SORT_BY_NUMBER,
  percentage: SORT_BY_NUMBER,
  user: () => SORT_BY_NAME,
  duration: SORT_BY_DATE,
  i18n: SORT_BY_STRING,
  undefined: SORT_BY_STRING,
  numberWithComma: SORT_BY_NUMBER
};

export const sortArray = (array, oldIndex, newIndex) => {
  const field = { ...array[oldIndex] };
  const without = [...array.slice(0, oldIndex), ...array.slice(oldIndex + 1)];
  const withField = [...without.slice(0, newIndex), field, ...without.slice(newIndex)];
  return withField;
};
