import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import HeaderRow from './HeaderRow';
import useDrawer from './hooks/useDrawer';
import useGridDimensions from './hooks/useGridDimensions';
import useViewportColumns from './hooks/useViewportColumns';
import useViewportRows from './hooks/useViewportRows';
import useVisibleColumn from './hooks/useVisibleColumns';
import Row from './Row';
import { Root, LoaderWrapper, PlaceholderRow } from './styles';
import useCheckboxSelection from './hooks/useCheckboxSelection';
import useSpecificCheckboxColumn from './hooks/useSpecificCheckboxColumn';

const CheckBoxDataGrid = ({
  // Grid props
  columns: allColumns,
  data: allRows,

  // Dimension props
  rowHeight = 30,
  customHeaderRowHeight = rowHeight,
  gridHeight: customGridHeight,

  // Feature props
  sortable = false,
  sortBy,
  sortDirection,
  setSort,
  resizable = false,
  isLoading,
  defaultExtendsRows,
  isStickyDrawer,
  showColumnsMenu,
  showExtraTableActionRenderer = false,
  storageName,
  headless = false,
  checkboxData,
  reorder = false,
  allowFrozenUntilWidth,
  isRtl = false,
  isGroupedData = false,
  checkboxSelection = false,

  // Custom renders,
  CellRenderer = React.Fragment,
  HeaderCellRenderer = CellRenderer,
  noDataRenderer,
  loadingRenderer,
  columnsActionListRenderer,
  extraTableActionIconRenderer,
  columnsMenuRenderer,
  actionRenderer,
  checkboxRenderer,

  // Events props
  onRowClick,
  onRowsRendered,
  setGridApi,
  onExpandClick,

  //Styling props
  className,
  style,
  rowStyle,

  //Virtualization
  isVirtualized = true
}) => {
  /**
   * states
   */
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [columnWidths, setColumnWidths] = useState(() => new Map());

  /**
   * computed values
   */
  const [gridRef, gridWidth, gridHeight] = useGridDimensions();
  const headerRowHeight = headless ? 0 : customHeaderRowHeight;
  const clientHeight = (customGridHeight ?? gridHeight) - headerRowHeight;

  const {
    visibleColumns,
    updateVisibleColumns,
    isMenuOpen,
    setIsMenuOpen,
    resetToDefault,
    columnStorageName,
    setColumnStorageName
  } = useVisibleColumn(allColumns, storageName);

  const { flatRows, extendedRows, toggleRow, firstVisibleRowIndex, setFirstVisibleRowIndex } = useDrawer({
    rows: allRows,
    defaultExtendsRows
  });

  useEffect(() => {
    onExpandClick?.(extendedRows);
  }, [extendedRows, onExpandClick]);

  const withCheckboxColumns = useSpecificCheckboxColumn({
    columns: visibleColumns,
    checkboxData,
    checkboxRenderer,
    isGroupedData
  });

  const { columns, rowSelection, clearRowSelection, getRowSelection } = useCheckboxSelection({
    checkboxSelection,
    columns: withCheckboxColumns,
    rowGroup: isGroupedData,
    rows: allRows
  });

  const { viewportColumns, layoutCssVars, totalColumnWidth, columnMetrics } = useViewportColumns({
    rawColumns: columns,
    columnWidths,
    scrollLeft,
    viewportWidth: gridWidth,
    sortable,
    resizable,
    allowFrozenUntilWidth
  });

  const { rowVisibleStartIdx, rowOverscanStartIdx, rowOverscanEndIdx, rows } = useViewportRows({
    rawRows: flatRows,
    rowHeight,
    clientHeight,
    scrollTop
  });

  const [gridApi, setGridApiRef] = useState({
    rows: allRows,
    rowSelection,
    clearRowSelection,
    getRowSelection
  });

  useEffect(() => {
    const api = {
      rows: allRows,
      rowSelection,
      clearRowSelection,
      getRowSelection
    };
    setGridApiRef(api);
    setGridApi?.(api);
  }, [allRows, clearRowSelection, getRowSelection, rowSelection, setGridApi]);

  useEffect(() => {
    isStickyDrawer && setFirstVisibleRowIndex(rowVisibleStartIdx);
  }, [isStickyDrawer, rowVisibleStartIdx, setFirstVisibleRowIndex]);

  useEffect(() => {
    onRowsRendered?.({ startIndex: rowOverscanStartIdx, stopIndex: rowOverscanEndIdx });
  }, [onRowsRendered, rowOverscanEndIdx, rowOverscanStartIdx]);

  const handleColumnResize = useCallback(
    (column, width) => {
      setColumnWidths((prevColumnWidths) => {
        const newColumnWidths = new Map(prevColumnWidths);
        if (newColumnWidths.size === 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [col, value] of columnMetrics) {
            newColumnWidths.set(col.dataKey, value.width);
          }
        }
        newColumnWidths.set(column.dataKey, width);
        return newColumnWidths;
      });
    },
    [columnMetrics]
  );

  const handleScroll = useCallback((event) => {
    setScrollTop(event.currentTarget.scrollTop);
    setScrollLeft(Math.abs(event.currentTarget.scrollLeft));
  }, []);

  const getRow = useCallback(
    (row, index, top) => {
      return (
        <Row
          key={index}
          rowIdx={index}
          index={index}
          row={row}
          viewportColumns={viewportColumns}
          top={top}
          rowStyle={rowStyle || row?.rowStyle}
          CellRenderer={CellRenderer}
          onRowClick={onRowClick}
          toggleRow={toggleRow}
          isExpended={
            extendedRows && row?.parentIndex && row.parentIndex in extendedRows ? extendedRows[row.parentIndex] : false
          }
          actionRenderer={actionRenderer}
          gridRef={gridRef}
          reorder={reorder}
          disabled={!reorder}
          gridApi={gridApi}
        />
      );
    },
    [
      viewportColumns,
      rowStyle,
      CellRenderer,
      onRowClick,
      toggleRow,
      extendedRows,
      actionRenderer,
      gridRef,
      reorder,
      gridApi
    ]
  );

  const lastRow = useMemo(() => rows?.[rows?.length - 1], [rows]);

  // workaround for displaying the last row because the sticky summary row hides it
  const getPlaceholderRow = useCallback(() => {
    return (
      <PlaceholderRow
        style={{
          height: rowHeight,
          width: '100%',
          top: (rows?.length - 1) * rowHeight + headerRowHeight
        }}
      />
    );
  }, [headerRowHeight, rowHeight, rows]);

  const getRowElements = useCallback(
    (from, to) => {
      const rowElements = [];
      for (let index = from; index <= to; index++) {
        const row = rows[index];
        const top = index * rowHeight + headerRowHeight;
        rowElements.push(getRow(row, index, top));
      }
      if (lastRow?.summarized) {
        rowElements.push(getPlaceholderRow());
        rowElements.push(getRow(lastRow, rows.length + 1, clientHeight));
      }
      return rowElements;
    },
    [clientHeight, getPlaceholderRow, getRow, headerRowHeight, lastRow, rowHeight, rows]
  );

  const getViewportRowsVirtualized = useCallback(() => {
    const limit = lastRow?.summarized ? (rowOverscanEndIdx === rows.length ? 2 : 1) : 0;
    return getRowElements(rowOverscanStartIdx, rowOverscanEndIdx - limit);
  }, [getRowElements, lastRow?.summarized, rowOverscanEndIdx, rowOverscanStartIdx, rows.length]);

  const getViewportRowsNotVirtualized = useCallback(() => {
    const limit = lastRow?.summarized ? 2 : 1;
    return getRowElements(0, rows.length - limit);
  }, [lastRow?.summarized, getRowElements, rows.length]);

  const openColumnsMenu = useMemo(() => isMenuOpen && showColumnsMenu && columnStorageName === storageName, [
    columnStorageName,
    isMenuOpen,
    showColumnsMenu,
    storageName
  ]);

  const getStickyRow = () => {
    const parentIndex = rows[firstVisibleRowIndex]?.parentIndex;
    if (parentIndex >= 0 && allRows[parentIndex] && extendedRows[parentIndex]) {
      return (
        <Row
          key="Drawer"
          index={9999}
          rowIdx={parentIndex}
          row={{ ...allRows[parentIndex], isGroup: true, parentIndex }}
          viewportColumns={viewportColumns}
          rowStyle={rowStyle}
          CellRenderer={CellRenderer}
          onRowClick={onRowClick}
          toggleRow={toggleRow}
          isExpended={extendedRows[parentIndex]}
          actionRenderer={actionRenderer}
          gridRef={gridRef}
          reorder={reorder}
          disabled={!reorder}
          isSticky
          gridApi={gridApi}
        />
      );
    }
  };

  const onClose = useCallback(() => {
    setColumnStorageName(null);
    setIsMenuOpen(false);
  }, [setColumnStorageName, setIsMenuOpen]);

  const uponUpdateVisibleColumns = useCallback(
    (selectedColumns) => {
      setColumnStorageName(null);
      updateVisibleColumns(selectedColumns);
    },
    [setColumnStorageName, updateVisibleColumns]
  );

  return (
    <>
      <Root
        id="data-grid"
        ref={gridRef}
        onScroll={isVirtualized ? handleScroll : undefined}
        className={className}
        style={{
          ...style,
          '--header-row-height': `${headerRowHeight}px`,
          '--row-width': `${totalColumnWidth}px`,
          '--row-height': `${rowHeight}px`,
          ...layoutCssVars,
          minHeight: customGridHeight,
          maxHeight: customGridHeight
        }}
        $isGroupedData={isGroupedData}
        $scrolledLeft={scrollLeft > 0}
        $isLoading={isLoading}>
        {isLoading && loadingRenderer && <LoaderWrapper>{loadingRenderer()}</LoaderWrapper>}

        {!headless && (
          <HeaderRow
            columns={viewportColumns}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={setSort}
            onColumnResize={handleColumnResize}
            HeaderCellRenderer={HeaderCellRenderer}
            showColumnsMenu={showColumnsMenu}
            columnsActionListRenderer={columnsActionListRenderer}
            extraTableActionIconRenderer={extraTableActionIconRenderer}
            showExtraTableActionRenderer={showExtraTableActionRenderer}
            setIsMenuOpen={setIsMenuOpen}
            storageName={storageName}
            setColumnStorageName={setColumnStorageName}
            isRtl={isRtl}
            gridApi={gridApi}
          />
        )}

        {!isLoading && rows.length === 0 && <LoaderWrapper>{noDataRenderer?.()}</LoaderWrapper>}

        {rows.length > 0 && (
          <>
            {isStickyDrawer && getStickyRow()}

            {/* <div
              style={{ height: Math.max(rows.length * rowHeight, clientHeight - (hasStickyRow() ? rowHeight : 0)) }}
            /> */}

            {/* <div style={{ height: '100%', overflow: 'scroll' }} />} */}

            {isVirtualized ? getViewportRowsVirtualized() : getViewportRowsNotVirtualized()}
          </>
        )}
      </Root>

      {openColumnsMenu &&
        columnsMenuRenderer?.({
          allColumns,
          visibleColumns,
          onClose,
          updateVisibleColumns: uponUpdateVisibleColumns,
          resetToDefault
        })}
    </>
  );
};

export default SortableContainer(CheckBoxDataGrid);
