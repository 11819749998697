import moment from 'moment';
import { API_DATE_FORMAT, API_DATE_TIME_FORMAT } from '@ubeya/shared/constants';
import { required } from '@ubeya/shared/utils/form';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { generateShiftName } from '@ubeya/shared/utils/scheduling/generateShiftName';
import React from 'react';
import privileges from '../../privileges';
import { EMPLOYEE_CLIENT_PREFERENCE_STATUS } from './constants';

const COMPLIANCE_STATUS_BY_TYPE = {
  COMPLIANT: 1,
  PARTIAL: 2,
  NOT_COMPLIANT: 0
};

const validateText = ({ value, t, metadata }) => {
  const { maxNumberOfChar, limitType } = metadata || {};

  if (!value || !maxNumberOfChar) {
    return;
  }

  switch (limitType) {
    case 'exact':
      if (value.length !== Number(maxNumberOfChar)) {
        return t('passedExactChar', { maxNumberOfChar });
      }
      break;
    case 'max':
    default:
      if (value.length > Number(maxNumberOfChar)) {
        return t('passedMaxChar', { maxNumberOfChar });
      }
  }
};

const validateDocumentExpirationDate = ({ t, value }) => {
  if (!value) {
    return;
  }

  const uploadedFile = value[0];

  if (uploadedFile?.status === 'deleted') {
    return;
  }

  if (!uploadedFile.expirationDate) {
    return t('required');
  }

  // if (moment(uploadedFile.expirationDate).isBefore(moment())) {
  //   return t('expirationDatePassed');
  // }
};

export const validateFactory = ({ value, fieldTypeId, t, metadata, validate, hasExpiration, isRequired }) => {
  if (isRequired) {
    return required(value);
  }
  const defaultValidate = validate && validate(value);

  if (defaultValidate) {
    return defaultValidate;
  }

  switch (fieldTypeId) {
    case 1:
      return validateText({ value, t, metadata });

    default:
      break;
  }

  if (hasExpiration) {
    return validateDocumentExpirationDate({ t, value });
  }
};

export const validateExpirationDate = ({ t, required: funcRequired, value }) => {
  if (!value) {
    return funcRequired ? t('required') : null;
  }

  if (moment(value).isBefore(moment())) {
    return t('expirationDatePassed');
  }
};

export const employeeComplianceStatus = ({ fields, minExpirationDate, positionComplianceFields, isCompliant }) => {
  if (isCompliant) {
    return {
      status: minExpirationDate
        ? moment(moment().format(API_DATE_FORMAT)).isSameOrBefore(moment(minExpirationDate).format(API_DATE_FORMAT))
          ? COMPLIANCE_STATUS_BY_TYPE.COMPLIANT
          : COMPLIANCE_STATUS_BY_TYPE.NOT_COMPLIANT
        : COMPLIANCE_STATUS_BY_TYPE.COMPLIANT
    };
  }

  const filledFieldsIdsArr = fields.filter((field) => !!field?.value?.isFieldCompliant).map((field) => field?.id);
  const filledFieldsIdsSet = new Set(filledFieldsIdsArr);

  const unfilledFieldsIds = positionComplianceFields.filter((fieldId) => !filledFieldsIdsSet.has(fieldId));

  // Partial compliance
  if (filledFieldsIdsSet.size > 0) {
    return {
      status: COMPLIANCE_STATUS_BY_TYPE.PARTIAL,
      filled: filledFieldsIdsSet.size || 0,
      unfilledFieldsIds,
      total: positionComplianceFields.length
    };
  }

  return { status: COMPLIANCE_STATUS_BY_TYPE.NOT_COMPLIANT, unfilledFieldsIds };
};

export const formatPhone = (phone) => {
  if (!phone) {
    return phone;
  }

  return phone.startsWith('9720') ? phone.replace('9720', '972') : phone;
};

// Support multiple links in a text
export const URL_REGEX = /\b(?:https?|ftp):\/\/[^\s]+/gi;

/**
 *  Find all possible links within text.
 *  @param text (optional) The input text that may contain URLs
 *  @returns The array of links found any, or null otherwise.
 */
export const findLinksInText = (text = '') => {
  const foundLinks = text.match(URL_REGEX);

  const createTextSpan = (startIndex, endIndex) =>
    React.createElement('span', { key: `text-${startIndex}-${endIndex}` }, text.substring(startIndex, endIndex));

  const createLinkElement = (url, index) => {
    const correctCommonProtocol = url.startsWith('http://') || url.startsWith('https://') || url.startsWith('ftp://');
    const normalizedUrl = correctCommonProtocol ? url : `http://${url}`;

    return React.createElement(
      'a',
      { key: `link-${index}`, href: normalizedUrl, target: '_blank', rel: 'noopener noreferrer' },
      url
    );
  };

  let lastIndex = 0;
  const result = [];

  (foundLinks || []).forEach((url, index) => {
    const matchIndex = text.indexOf(url, lastIndex);

    // Add non-link text between the previous match and the current match
    if (matchIndex > lastIndex) {
      result.push(createTextSpan(lastIndex, matchIndex));
    }

    // Add the link as an <a> tag
    result.push(createLinkElement(url, index));

    // Update lastIndex to the end of the current match
    lastIndex = matchIndex + url.length;
  });

  // Add any remaining non-link text after the last match
  if (lastIndex < text.length) {
    result.push(createTextSpan(lastIndex, text.length));
  }

  return result;
};

export const removeTagsFromText = (text = '') => text.replace(/<[^>]*>/g, '');

export const getClientsNamesByStatus = ({ employeeClientsPreferences = [], mappedClients = {}, clientStatus = null }) =>
  employeeClientsPreferences
    .filter(({ status }) => status === clientStatus)
    .map(({ clientId }) => mappedClients?.[clientId]?.name || '');

export const formatExportEmployees = ({
  t,
  mappedBranches,
  mappedPositions,
  mappedEmploymentTypes,
  mappedClients,
  fields,
  mappedFieldsValue,
  timeFormat,
  dateFormat,
  employees = []
}) =>
  employees.reduce((prev, employee) => {
    const phoneUtils = employee.phone && parsePhoneNumberFromString(`+${employee.phone}`);
    const phoneFormatted = !phoneUtils ? '' : phoneUtils.formatInternational?.();
    const preferredClientsNames = getClientsNamesByStatus({
      employeeClientsPreferences: employee?.employeeClientsPreferences,
      mappedClients,
      clientStatus: EMPLOYEE_CLIENT_PREFERENCE_STATUS.PREFERRED
    });
    const blockedClientsNames = getClientsNamesByStatus({
      employeeClientsPreferences: employee?.employeeClientsPreferences,
      mappedClients,
      clientStatus: EMPLOYEE_CLIENT_PREFERENCE_STATUS.BLOCKED
    });

    return [
      ...prev,
      {
        [t('firstName')]: employee.firstName,
        [t('lastName')]: employee.lastName,
        [t('fullName')]: employee.fullName,
        [t('createdAt')]: moment(employee.createdAt, API_DATE_TIME_FORMAT).isValid()
          ? moment(employee.createdAt, API_DATE_TIME_FORMAT).format(`${dateFormat} ${timeFormat}`)
          : '',
        [t('phone')]: phoneFormatted,
        [t('email')]: employee.email ?? '',
        [t('blockedClients')]: blockedClientsNames.join(', '),
        [t('preferredClients')]: preferredClientsNames.join(', '),
        [t('branches')]: employee.branches.map((branch) => mappedBranches[branch]?.name).join(', '),
        [t('positions')]: employee.positions.map((position) => mappedPositions[position]?.label).join(', '),
        [t('shift')]: employee.isShift ? t('yes') : '',
        [t('daily')]: employee.isDaily ? t('yes') : '',
        [t('notes')]: employee.notes ?? '',
        [t('employmentType')]: mappedEmploymentTypes[employee.employmentTypeId]?.label || '',
        [t('employeeId')]: employee.employeeIdField || '',
        ...fields.reduce(
          (prevFields, { name, id }) => ({
            ...prevFields,
            [name]: mappedFieldsValue[id](employee.fields.find((field) => field.id === id)?.value) || ''
          }),
          {}
        ),
        [t('lastActivity')]: moment(employee.lastActivity, API_DATE_TIME_FORMAT).isValid()
          ? moment(employee.lastActivity, API_DATE_TIME_FORMAT).format(`${dateFormat} ${timeFormat}`)
          : '',
        [t('lastComment')]: employee.lastComment ? removeTagsFromText(employee.lastComment?.comment) : '',
        [t('appUser')]: employee.hasApp ? t('yes') : '',
        [t('uploadedProfilePicture')]: employee.image ? t('yes') : t('no'),
        [t('isPushNotification')]: employee.isPushNotification ? t('yes') : ''
      }
    ];
  }, []);

export const formatExportAdmins = ({ t, admins, mappedBranches, mappedPrivileges }) =>
  admins.reduce((prev, { admin = {}, email, firstName, lastName, fullName }) => {
    const privilegesList = (admin.privileges || []).map(({ id }) => ({
      id,
      label: mappedPrivileges[id]
    }));

    const privilegesIds = (privilegesList || []).map(({ id }) => id);

    const adminBranches = (admin?.branches || []).map((branchId) => ({
      id: branchId,
      name: mappedBranches[branchId]
    }));

    return [
      ...prev,
      {
        [t('firstName')]: firstName,
        [t('lastName')]: lastName,
        [t('fullName')]: fullName,
        [t('email')]: email ?? '',
        [t('employeesHeaderManagerType')]: (privilegesIds || []).includes(privileges.BRANCH_ADMIN)
          ? t('branch_owner')
          : (privilegesIds || []).includes(privileges.ACCOUNT_ADMIN)
          ? t('account_owner')
          : '',
        [t('employeesHeaderAdminBranches')]:
          adminBranches && adminBranches.length > 0
            ? adminBranches.map(({ name }) => name.name)?.join(', ')
            : t('allBranches'),
        [t('employeesHeaderPrivileges')]: privilegesList.map(({ label }) => label)?.join(', ')
      }
    ];
  }, []);

export const formatExportCandidates = ({
  candidates,
  timeFormat,
  dateFormat,
  showRequestedBranch,
  t,
  mappedLabels,
  mappedLocations
}) => {
  return candidates.reduce(
    (prev, { shift = {}, project = {}, shiftId, source, branch, extraInformation, createdAt, user }) => [
      ...prev,
      {
        [t('firstName')]: user.firstName,
        [t('lastName')]: user.lastName,
        [t('phone')]: user.phone ? parsePhoneNumberFromString(`+${user.phone}`)?.formatNational?.() : '',
        [t('createdAt')]: moment.utc(createdAt).local().format(`${dateFormat} ${timeFormat}`),
        ...(showRequestedBranch && { [t('requestedBranch')]: branch.name ?? '' }),
        [t('candidateSource')]: t(source),
        [t('extraInformation')]: extraInformation,
        [t('projectName')]: shiftId
          ? generateShiftName({ ...shift, ...project }, undefined, mappedLabels, mappedLocations)
          : ''
      }
    ],
    []
  );
};
